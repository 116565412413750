import logo from './logo.svg';
import './App.css';
import ScanditBarcodeScanner from "scandit-sdk-react";
import {
  Barcode,
  BarcodePicker,
  Camera,
  CameraAccess,
  CameraSettings,
  ScanSettings,
  SingleImageModeSettings,
} from "scandit-sdk";
import React from "react";
import zlib from "react-zlib-js";
import useSound from 'use-sound';
import goodBeep from './scanok.mp3';
import shortLowHigh from './short_low_high.mp3';
import badBeep from './scanbad.mp3';
var catMat = { categories: {    
  a: "Vuxen",
  b: "Pensionär",
  c: "Barn",
  d: "Hund",
  e: "Student",
  f: "Familj",
  g: "Cykel",
  h: "Ungdom"
}};
function getElapsedTimeString(dateString) {
  const year = dateString.slice(0, 4);
  const month = dateString.slice(4, 6) - 1; // month is 0-indexed in JavaScript
  const day = dateString.slice(6, 8);
  const hour = dateString.slice(9, 11);
  const minute = dateString.slice(11, 13);
  const date = new Date(year, month, day, hour, minute);

  const currentDate = new Date();
  const elapsedTimeInMilliseconds = currentDate.getTime() - date.getTime();

  const millisecondsPerSecond = 1000;
  const millisecondsPerMinute = 60 * millisecondsPerSecond;
  const millisecondsPerHour = 60 * millisecondsPerMinute;
  const millisecondsPerDay = 24 * millisecondsPerHour;

  let elapsedString = '';
  let timeLabel = '';

  const days = Math.floor(elapsedTimeInMilliseconds / millisecondsPerDay);
  if (days > 0) {
    elapsedString += `${days}d `;
    timeLabel = 'sedan';
  }

  const hours = Math.floor((elapsedTimeInMilliseconds % millisecondsPerDay) / millisecondsPerHour);
  if (hours > 0) {
    elapsedString += `${hours}h `;
    if (timeLabel === '') {
      timeLabel = 'sedan';
    }
  }

  const minutes = Math.floor((elapsedTimeInMilliseconds % millisecondsPerHour) / millisecondsPerMinute);
  if (minutes > 0) {
    elapsedString += `${minutes}m `;
    if (timeLabel === '') {
      timeLabel = 'sedan';
    }
  }

  const seconds = Math.floor((elapsedTimeInMilliseconds % millisecondsPerMinute) / millisecondsPerSecond);
  if (seconds > 0) {
    elapsedString += `${seconds}s`;
    if (timeLabel === '') {
      timeLabel = 'sedan';
    }
  }

  if (elapsedString === '') {
    elapsedString = '0s';
  }

  if (timeLabel === '') {
 //   elapsedString += "om ";
    //timeLabel = 'om';
  }

  return (timeLabel === '' ? "om " : "") + `${elapsedString.trim()} ${timeLabel}`;
}
function extractTicketData(inp) {
  var ticketData = { tickLang:"", id: "", category: "", travelers: "", start: "", expiry: "", region: "" };
  //var tick = buffer.toString('ascii');
  var tick = inp;
  // console.log(tick)
  {
    var regex = new RegExp('(?<=ctid)(.*)(?=!ct)', 'gm')
    var tickData = tick.match(regex)[0];
    ticketData.id = tickData;
  }
  {
    var regex = new RegExp('(?<=ccat)(.*)(?=ccls)', 'gm')
    var tickData = (tick.match(regex) == null ? 0 : tick.match(regex).length);
    if(tickData == 0) {
      regex = new RegExp('(?<=ccat)(.*)(?=ctra)', 'gm')
     tickData = tick.match(regex).length;
    }
    if(tickData > 1) {
      for(var s = 0; s < tickData; s++) {
        ticketData.category += tick.match(regex) + ", ";
      }
    } else {
      ticketData.category = tick.match(regex)[0];
    }
    var news = "";
    for (var i = 0; i < ticketData.category.length; i++) {
      var sl = ticketData.category.charAt(i);
      if(catMat.categories[sl] != null ) {
        news += catMat.categories[sl] + ", ";
      }
    }
    news += "(" + ticketData.category + ")";
    ticketData.category = news;
  }
  
  {
    var regex = new RegExp('(?<=ctra)(.*)(?=ctrs)', 'gm')
    var tickData = (tick.match(regex) == null ? 0 : tick.match(regex).length);
    if(tickData == 0) {
      regex = new RegExp('(?<=ctra)(.*)(?=ccat)', 'gm')
       tickData = (tick.match(regex) == null ? 0 : tick.match(regex).length);
      if(tickData == 0) {
      regex = new RegExp('(?<=ctra)(.*)(?=dc_tcx)', 'gm')
       tickData = (tick.match(regex) == null ? 0 : tick.match(regex).length);
    }
    }
    if(tickData > 1) {
      for(var s = 0; s < tickData; s++) {
        ticketData.travelers += tick.match(regex) + ", ";
      }
    } else {
      ticketData.travelers = tick.match(regex)[0];
    }
    ticketData.travelers = ticketData.travelers.split("!")[0];

  }
  {
    var regex = new RegExp('(?<=dc_tc)(.*)(?=X@)', 'gm')
    var tickData;
 
      tickData = tick.match(regex)[0];
    
   
    ticketData.tickLang = tickData;
  }
  tick = ticketData.tickLang + "X@";
  {
    var regex = new RegExp('(?<=@)(.*)(?=Z\\/)', 'gm')
    var tickData = tick.match(regex)[0];
    ticketData.start = tickData;
  }
  {
    var regex = new RegExp('(?<=Z\\/2)(.*)(?=Z )', 'gm')
    var tickData;


    if(tick.match(regex) == null || tick.match(regex).length == 0 ){
      regex = new RegExp('(?<=Z\\/)(.*)(?=dc_ve)', 'gm')
       tickData = tick.match(regex)[0];
    } else {
      tickData = "2" + tick.match(regex)[0];
    }
   
    ticketData.expiry = tickData;
  }
  {
    var regex = new RegExp('(?<=& #)(.*)(?=dc_vma)', 'gm')
    var tickData;
    if(tick.match(regex) == null || tick.match(regex).length == 0 ){
      regex = new RegExp('(?<=&)(.*)(?=X@)', 'gm')
       tickData = tick.match(regex)[0];
    } else {
      tickData = tick.match(regex)[0];
    }
   
    ticketData.region = tickData;
    if(ticketData.region.length >= 32 ) {
      ticketData.region = "Okänd, kolla biljettspråk";
    }
  }

  return ticketData;
 // alert("Ticket ID: " + tickData);
}

function App2() {
  const [scannerReady, setScannerReady] = React.useState(false);
  const [lastScan, setLastScan] = React.useState("");
  const [showModal, setShowModal] = React.useState(false);

  const [playGood] = useSound(goodBeep, { volume: 1 });
  const [playShortLowHigh] = useSound(shortLowHigh);
  const [playBad] = useSound(badBeep);
  let audio = new Audio("./goodbeep.mp3")
  const [ticketInfo, setTicketInfo] = React.useState();
function convertToJSDate(dateString) {
  const year = dateString.slice(0, 4);
const month = dateString.slice(4, 6) - 1; // month is 0-indexed in JavaScript
const day = dateString.slice(6, 8);
const hour = dateString.slice(9, 11);
const minute = dateString.slice(11, 13);

const date = new Date(year, month, day, hour, minute);
return date;
}
  const start = () => {
    playGood();
  }
const handleCloseModal = () => {
  setShowModal(false);
}
  React.useEffect(() => {
    if (lastScan != "") {
     if(ticketInfo.id == "EXTERN"){
      playShortLowHigh();
     } else {
      if (convertToJSDate(ticketInfo.expiry).getTime() < new Date().getTime()) {
        playBad();
      } else {
        playGood();
      }
    }
      //  playBad();
      //playShortLowHigh();
      setLastScan("");
    }
  }, [lastScan]);
  const doSynthetic = (ticketId, lang, cat) => {
    setLastScan("aa");
    setTicketInfo({ tickLang:lang, id: ticketId, category: cat, travelers: "?", start: "200010101010", expiry: "210010101010", region: "?" });
    setShowModal(true);
   
  }
  return (<>
    {showModal ? (
      <div className="modal">
        <div className="modal-content">
       

{(ticketInfo.expiry.length == 13 ? <h2>BILJETT {convertToJSDate(ticketInfo.expiry).getTime() > new Date().getTime() ? "GILTIG" : "OGILTIG"}</h2> : <h2>BILJETT</h2>)}
          <h3>Biljett ID: {ticketInfo.id}</h3>
          <h3>Kategorier: {ticketInfo.category}</h3>
          <h3>Antal resenärer: <xmp>{ticketInfo.travelers}</xmp></h3>
          <h3>Start giltighet: {ticketInfo.start} ({getElapsedTimeString(ticketInfo.start)})</h3>
          <h3>Slut giltighet: {ticketInfo.expiry} ({getElapsedTimeString(ticketInfo.expiry)})</h3>
          <h3>Regioner: {ticketInfo.region}</h3>
          <h3>Biljettspråk (rått): {ticketInfo.tickLang}</h3>

          <button onClick={handleCloseModal}>OK</button>
        </div>
      </div>
    ) :
    <div className="App">
      <header className="App-header">
      <button onClick={() => doSynthetic("EXTERN", "Pappersbiljett till/från Danmark", "?")}>PAPPERSBILJETT T/F DANMARK</button>
        <button onClick={() => doSynthetic("EXTERN", "Pappersbiljett Sverige", "?")}>PAPPERSBILJETT SVERIGE</button>
        <button onClick={() => doSynthetic("EXTERN", "Resekort länstrafik", "?")}>RESEKORT LÄNSTRAFIK</button>
        <button onClick={() => console.log("OK")}>UTFÄRDA VARNING</button>
        <ScanditBarcodeScanner
          // Library licensing & configuration options (see https://docs.scandit.com/stable/web/globals.html#configure)
        //  licenseKey={"AaNCcww5L16VN0WAriMVWTsju2QaBoqi0ku9191h9wLeZr4e/1/ypOFY9dBMMdR6eWIkDMxZG3X9Xiv64Tar9TNfnITiSJVY5EjNUQpkIMI0EQQqSzWLbooK+phcHnwKhco+pKYzI74DNAn4XFPFNclAW8/CDe9uKwf3Jn78NS713TOwqeaNIrpPaa9V43oczZ2mZ5xg4Z5iXjfx8ddhceZjNa/TUHIUXw260pWgepmZ0IxWHfsdMgliR8FLfuqEuHuEU7HNTbNZMpyBxJhuX17lcYfSzXQ6f3dxQ5YSchp2ecKypECzKicDoiEchgFgeebvsXYpp8nB8JRd+Qr2vIikHLJ6i9QTVkzjDrve5jxOLW1hTqF7nilMdU3j5CPsa5l6Cf5xLqHrCPwJ8J6DXh3MT4cNMtwZVnCyFybMiVteb3Aq0s5fqTqTNQgPQbtDrF/ORWidvulh7vKShCJzon7+ks75A2caC545pwAlgLZbKGaBaWcJWR4f+ISmcz0zUtWb2KvXIfgEs69saui6dpk1oojPspwtEJyxRs8fw8jmjzC+HOc4cDtmpBKBO618O4khZvghdPFApvDHVcZvWfltMlt98TOha406T3A6TsQKMzz3tNBX8fdMAEYDCUToQ55ed8a94mCtYRDyVBm0NstlhZH2AqfVLh8kfTCIkbkbY5Xgr8XXpIFKEATTNFyvbO/l7WeabXAiqHzYtdHAWTzUdPP+fFEgYgt43O4M6cv60X6uqz/mDDChZoUQTYritqQqEHfdsZc0mMz9VJC/qT/YUZngLJyDMuDSEozIcQ=="}
           licenseKey={"AaqyDxI5MU0kMnS3+xwQZWMycxOaC4qRL1UASYBqGtLzWiOszWWQLEFxNbgwV7biiAwP/MR84Qc9ShGpglrLtANZHPFKNhPWMjCHZxVYOcGoayqbXULW3zEiQ3cBHx0cuGWcXF1GRrE9d0a67Rhy3TZM2V/FWnjuWDxN1lxSYAm2XhtqfUAHrltmb9dYE0BZeARO0IQQ5Hw2OXaYTCItC+ApmvssSNrNEcsCoGnT26aFjILC1lrYSM68z8dwzVqL8NXyBRaMFVS2pZ0sWZ8i4K/874pyb9+rBROi6E+93W1H5NOGcBLIufxFYEWSrYkUjO/tP1eCcjXn+D4kweWI18G419sKoT2dTOy2qGszPfKRkSUy54BDGkgvDwB19amr/yvp10wqcC45eYDTaIQXQqGCvq15EY86H4VUACaDtPcErsxtlDW6nltHeyJfrPnGxNKWcFKm67YSQaoRE5opPujvRbIWV33+tHf9nWZ4jvDM19dtHK2jK8s3EjC0AD5VGskCkZawotq1gD/kVDrBoKD8B7vjrlDydqJR7u1ITTyoWCTvrc/qjKQwuO4dSbbit9idyphR8/S+vxrtUT0T1yZEfpyqyOQLaSnpImwiYjEMAXP5F2fKRmJX94bq2+PsY0JGVMIKI6/HFqZ3uC6PA3bryFhcdW0hwB6Nv0o2pPkEhHppFHWRTvXjzjTVKRBZ9zvIJdoc15LTMT1yoa8sjHkBpEd10bWBJedjV8MhliiEWBvsRBb3yzZgtBIHKla04QUF7LbgEPfcA4Nb3GB9NfV8KEUob6Dd6m2FJFEZ3+N5cV2bPshvCnITQIG8eVxO2UgCHI+AJ7KvPsj5oKMJpMDaoY29M6dWgdhEND9b72g4/JviStdW3AFFzmXSvSWLGfk="} 
          engineLocation="https://cdn.jsdelivr.net/npm/scandit-sdk@5.x/build" // could also be a local folder, e.g. "build"
          // Picker events

          onReady={() => setScannerReady(true)}
          onScan={(barcode) => {
            zlib.inflate(barcode.barcodes[0].rawData, (err, buffer) => {

              if (!err) {
             
                //start();
                //alert(buffer);
                //  playShortLowHigh();
                var tick = buffer.toString('base64');
              //  alert(tick);
           /*     console.log(tick)
                const regex = new RegExp('(?<=ctid)(.*)(?=!ct)', 'gm')
                //extractDataTicket("(?<=ctid)(.*)(?=¡)", buffer.toString());
                var tickData = tick.match(regex)[0];
                alert("Ticket ID: " + tickData);*/
                //setTicketInfo(extractTicketData(tick));
              //  setShowModal(true);
               // alert(JSON.stringify(extractTicketData(tick)))
                //console.log(buffer.toString('base64'));
               fetch("https://test-5bgx.onrender.com/validateTicket", {method:"POST",body:tick}).then((res) => res.json()).then((data) => {
                setLastScan("aa");
                setTicketInfo(data);
                setShowModal(true);
                });
              }
              else {
                console.log(err);
              }
            });
            // console.log(barcode.barcodes[0].rawData)
          }}
          onScanError={console.log}
          // Picker options

          scanSettings={new ScanSettings({ codeDuplicateFilter: 5000, enabledSymbologies: [Barcode.Symbology.AZTEC] })}
        />
      </header>
    </div>}</>
  );
}

function App() {
  const [started, setStarted] = React.useState(false);
  return (
    <>
      <button onClick={() => setStarted(true)}>{!started ? "Påbörja" : "Avsluta"} dagskontroll</button>
      {started ? <App2 /> : <a>Inte påbörjad</a>}
    </>

  );
}



export default App;
